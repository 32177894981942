import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "../components/layout/Header";
import callApi from "../lib/apisauce/callApi";

import MomL from "../components/svg/MomL";
import DadL from "../components/svg/DadL";
import KidML from "../components/svg/KidML";
import KidFL from "../components/svg/KidFL";

import MinorShape from "../components/svg/MinorShape";
import { useDispatch } from "react-redux";
import { resetNeedState } from "../redux/answeredNeedsSlice";
import { resetBadFeelState } from "../redux/badFeelSlice";
import { resetDeepNeedState } from "../redux/deepNeedsSlice";
import { resetGoodFeelState } from "../redux/goodFeelSlice";
import { resetIGNeedState } from "../redux/ignoredNeedsSlice";
import { resetMostAnsNeedState } from "../redux/mostAnsNeedSlice";
import { resetMostIgNeedState } from "../redux/mostIgNeedSlice";
import { resetMostGFState } from "../redux/mostGFSlice";
import { resetMostBFState } from "../redux/mostBFSlice";
// import { queryString } from "../utils/queryString";

const HomeL = () => {
  const navigate = useNavigate();
  const JWToken = JSON.parse(sessionStorage.getItem("UserJWT"));
  const userData = JSON.parse(sessionStorage.getItem("userData"));
  // let memberCode;
  // memberCode = userData?.member_code;

  useEffect(() => {
    if (JWToken === null) {
      navigate("/");
    }
    [
      "selfConnectData",
      "request",
      "judgement",
      "updateMode",
      "displayData",
      "isMyNeeds",
      "isPartnerNeeds",
      "finalSelect",
      "finalFreeText",
    ].forEach((key) => {
      sessionStorage.removeItem(key);
    });
  }, []);

  // useEffect(() => {
  //   if (
  //     memberCode &&
  //     memberCode !== "" &&
  //     ["11111a", "2222bb", "333ccc", "999999", "888888"].includes(memberCode)
  //   ) {
  //     checkMemberCodeExpiry();
  //   }
  // }, []);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetNeedState());
    dispatch(resetBadFeelState());
    dispatch(resetDeepNeedState());
    dispatch(resetGoodFeelState());
    dispatch(resetIGNeedState());
    dispatch(resetMostAnsNeedState());
    dispatch(resetMostIgNeedState());
    dispatch(resetMostBFState());
    dispatch(resetMostGFState());
  }, []);

  const [goodFeel, setGoodFeel] = useState(
    JSON.parse(sessionStorage.getItem("goodFeelings")) || []
  );
  const [badFeel, setBadFeel] = useState(
    JSON.parse(sessionStorage.getItem("badFeelings")) || []
  );
  const [needs, setNeeds] = useState(
    JSON.parse(sessionStorage.getItem("allNeeds")) || []
  );

  //gathering good-bad feelings & needs data, storing in session storage in advance for other pages.
  useEffect(() => {
    if (!goodFeel.length) fetchGoodFeelings();
    if (!badFeel.length) fetchBadFeelings();
    if (!needs.length) fetchAllNeeds();
  }, []);

  const logOut = () => {
    //incase of expired member code
    localStorage.removeItem("UserJWT");
    [
      "UserJWT",
      "userData",
      "badFeelings",
      "blackListWords",
      "allNeeds",
      "selfConnectData",
      "request",
      "judgement",
      "goodFeelings",
      "RegUserData",
      "updateMode",
      "displayData",
    ].forEach((key) => {
      sessionStorage.removeItem(key);
    });
    navigate("/");
  };

  // const checkMemberCodeExpiry = () => {
  //   callApi
  //     .postData("is-code-expired", queryString({ member_code: memberCode }), {
  //       headers: {
  //         "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
  //         Authorization: JWToken,
  //       },
  //     })
  //     .then((res) => {
  //       if (res.data.status === "success") {
  //         //do nothing, let the user play normally
  //       } else {
  //         if (res.data.message === "תוקף הקוד פג") {
  //           //code expired
  //           console.log(res.data.message);
  //           sessionStorage.setItem("ExpireCode", "True");
  //           logOut();
  //         }
  //       }
  //     })
  //     .catch((res) => console.log(res.originalError));
  // };

  const fetchGoodFeelings = () => {
    callApi
      .getDatawithToken(
        "get-good",
        {},
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
            Authorization: JWToken,
          },
        }
      )
      .then((res) => {
        if (res.data.status === "success") {
          setGoodFeel(res.data.data);
          sessionStorage.setItem("goodFeelings", JSON.stringify(res.data.data));
        }
      })
      .catch((res) => console.log(res.originalError));
  };

  const fetchBadFeelings = () => {
    callApi
      .getDatawithToken(
        "get-bad",
        {},
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
            Authorization: JWToken,
          },
        }
      )
      .then((res) => {
        if (res.data.status === "success") {
          setBadFeel(res.data.data);
          sessionStorage.setItem("badFeelings", JSON.stringify(res.data.data));
        }
      })
      .catch((res) => console.log(res.originalError));
  };

  const fetchAllNeeds = () => {
    callApi
      .getDatawithToken(
        "get-needs",
        {},
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
            Authorization: JWToken,
          },
        }
      )
      .then((res) => {
        if (res.data.status === "success") {
          let NData = [...res?.data?.data];

          sessionStorage.setItem("allNeeds", JSON.stringify(NData));
          setNeeds(res?.data?.data);
        }
      })
      .catch((res) => {
        console.log(res.originalError);
      });
  };

  let familyRole = userData?.family_role;

  const handleClick = () => {
    //if there is single option to request from, redirect to enter name page.
    if (
      ["mom", "dad", "adult_f", "adult_m"].includes(userData?.family_role) &&
      userData?.kids_exist === "without_children" &&
      (["female", "male"].includes(userData?.adult_relationship) ||
        ["female", "male"].includes(userData?.parent_relationship))
    ) {
      let relationWith =
        userData?.adult_relationship === "female" ||
        userData?.parent_relationship === "female"
          ? "other_f"
          : "other_m";
      const requestData = JSON.stringify({
        ask_from_partner_roll: relationWith,
      });
      sessionStorage.setItem("request", requestData);
      navigate("/partner-name");
    } else {
      navigate("/request-from");
    }
  };

  return (
    <>
      <Header extraClass="border-bottom bg-white" />
      <div className="flex_home flex-minus pt-60">
        {/* A7 Content Section Start */}
        <div className="a7_content ">
          <div className="container">
            <div className="a7_header_content text-center">
              {(familyRole === "mom" || familyRole === "adult_f") && <MomL />}
              {(familyRole === "dad" || familyRole === "adult_m") && <DadL />}
              {familyRole === "kid_f" && <KidFL />}
              {familyRole === "kid_m" && <KidML />}

              <h4>{userData?.name}</h4>
              <h4>מה יכול לעזור לך עכשיו?</h4>
            </div>
          </div>
        </div>
        {/* A7 Content Section End */}

        {/* Circle design start */}
        <div className="footer_a7 he-un" id="fixedBG">
          <div className="Full_circle_a7" id="circleRounded">
            <div className="container">
              <div className="full_a7_center">
                <div className="circle_1">
                  <Link to="/situation-against" className="line">
                    <h6>היפוך שיפוטים לצרכים</h6>
                  </Link>
                </div>
                <div className="circle_2">
                  <Link to="/feel-need-text" className="line">
                    <h6>רשימת רגשות וצרכים</h6>
                  </Link>
                </div>
                <div className="circle_3">
                  {/* self-connect */}
                  <Link to="/self-connect" className="line">
                    <h6>חיבור לעצמי</h6>
                  </Link>
                </div>
                <div className="circle_4" onClick={handleClick}>
                  {/* request */}
                  <h6>ניסוח בקשה</h6>
                </div>
              </div>
            </div>
          </div>

          <div className="svg-img svg-img_home">
            {/* <img src="assets/images/minor-shape.svg" /> */}
            <MinorShape />
          </div>
          <div className="footer_a7_content bg-blue-home pt-2">
            <div className="container px-50">
              <a
                href="#"
                className="line"
                data-bs-toggle="modal"
                data-bs-target="#youtube_modal"
              >
                <button className="btn bg-white-outline">סרטון הסבר</button>
              </a>
            </div>
          </div>
          <div className="footer_home_logo1 ">
            <div>
              <img src="assets/images/white-logo.svg" alt="Logo" />
              <p className="m-0">
                © כל הזכוייות שמורות ל-{" "}
                <a href="https://www.mpathicfamily.com/">Mpathic family</a>
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* Circle design end */}
    </>
  );
};

const HomeS = () => {
  const navigate = useNavigate();
  const JWToken = JSON.parse(sessionStorage.getItem("UserJWT"));
  const userData = JSON.parse(sessionStorage.getItem("userData"));
  // let memberCode;
  // memberCode = userData?.member_code;

  useEffect(() => {
    if (JWToken === null) {
      navigate("/");
    }
    [
      "selfConnectData",
      "request",
      "judgement",
      "updateMode",
      "displayData",
      "isMyNeeds",
      "isPartnerNeeds",
      "finalSelect",
      "finalFreeText",
    ].forEach((key) => {
      sessionStorage.removeItem(key);
    });
  }, []);

  // useEffect(() => {
  //   if (
  //     memberCode &&
  //     memberCode !== "" &&
  //     ["11111a", "2222bb", "333ccc", "999999", "888888"].includes(memberCode)
  //   ) {
  //     checkMemberCodeExpiry();
  //   }
  // }, []);

  const [goodFeel, setGoodFeel] = useState(
    JSON.parse(sessionStorage.getItem("goodFeelings")) || []
  );
  const [badFeel, setBadFeel] = useState(
    JSON.parse(sessionStorage.getItem("badFeelings")) || []
  );
  const [needs, setNeeds] = useState(
    JSON.parse(sessionStorage.getItem("allNeeds")) || []
  );

  //gathering good-bad feelings & needs data, storing in session storage in advance for other pages.
  useEffect(() => {
    if (!goodFeel.length) fetchGoodFeelings();
    if (!badFeel.length) fetchBadFeelings();
    if (!needs.length) fetchAllNeeds();
  }, []);

  const logOut = () => {
    //incase of expired member code
    localStorage.removeItem("UserJWT");
    [
      "UserJWT",
      "userData",
      "badFeelings",
      "blackListWords",
      "allNeeds",
      "selfConnectData",
      "request",
      "judgement",
      "goodFeelings",
      "RegUserData",
      "updateMode",
      "displayData",
    ].forEach((key) => {
      sessionStorage.removeItem(key);
    });
    navigate("/");
  };

  // const checkMemberCodeExpiry = () => {
  //   callApi
  //     .postData("is-code-expired", queryString({ member_code: memberCode }), {
  //       headers: {
  //         "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
  //         Authorization: JWToken,
  //       },
  //     })
  //     .then((res) => {
  //       if (res.data.status === "success") {
  //         //do nothing, let the user play normally
  //       } else {
  //         if (res.data.message === "תוקף הקוד פג") {
  //           //code expired
  //           console.log(res.data.message);
  //           sessionStorage.setItem("ExpireCode", "True");
  //           logOut();
  //         }
  //       }
  //     })
  //     .catch((res) => console.log(res.originalError));
  // };

  const fetchGoodFeelings = () => {
    callApi
      .getDatawithToken(
        "get-good",
        {},
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
            Authorization: JWToken,
          },
        }
      )
      .then((res) => {
        if (res.data.status === "success") {
          setGoodFeel(res.data.data);
          sessionStorage.setItem("goodFeelings", JSON.stringify(res.data.data));
        }
      })
      .catch((res) => console.log(res.originalError));
  };

  const fetchBadFeelings = () => {
    callApi
      .getDatawithToken(
        "get-bad",
        {},
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
            Authorization: JWToken,
          },
        }
      )
      .then((res) => {
        if (res.data.status === "success") {
          setBadFeel(res.data.data);
          sessionStorage.setItem("badFeelings", JSON.stringify(res.data.data));
        }
      })
      .catch((res) => console.log(res.originalError));
  };

  const fetchAllNeeds = () => {
    callApi
      .getDatawithToken(
        "get-needs",
        {},
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
            Authorization: JWToken,
          },
        }
      )
      .then((res) => {
        if (res.data.status === "success") {
          let NData = res?.data?.data;
          sessionStorage.setItem("allNeeds", JSON.stringify(NData));
          setNeeds(res?.data?.data);
        }
      })
      .catch((res) => {
        console.log(res.originalError);
      });
  };

  let familyRole = userData?.family_role;

  const handleClick = () => {
    //if there is single option to request from, redirect to enter name page.
    if (
      ["mom", "dad", "adult_f", "adult_m"].includes(userData?.family_role) &&
      userData?.kids_exist === "without_children" &&
      (["female", "male"].includes(userData?.adult_relationship) ||
        ["female", "male"].includes(userData?.parent_relationship))
    ) {
      let relationWith =
        userData?.adult_relationship === "female" ||
        userData?.parent_relationship === "female"
          ? "other_f"
          : "other_m";
      const requestData = JSON.stringify({
        ask_from_partner_roll: relationWith,
      });
      sessionStorage.setItem("request", requestData);
      navigate("/partner-name");
    } else {
      navigate("/request-from");
    }
  };

  return (
    <>
      <Header extraClass="border-bottom bg-white" />
      <div className="flex_home">
        <div className="a7_content pt-60">
          <div className="container">
            <div className="a7_header_content text-center">
              {(familyRole === "mom" || familyRole === "adult_f") && <MomL />}
              {(familyRole === "dad" || familyRole === "adult_m") && <DadL />}
              {familyRole === "kid_f" && <KidFL />}
              {familyRole === "kid_m" && <KidML />}
              <h4>{userData?.name}</h4>
              <h4>מה יכול לעזור לך עכשיו?</h4>
            </div>
          </div>
        </div>
        <div className="footer_a7" id="fixedBG">
          <div className="Full_circle_a7" id="circleRounded">
            <div className="container">
              <div className="full_a7_center_s">
                <div className="circle_1s">
                  <Link to="/situation-against" className="line">
                    <h6>היפוך שיפוטים לצרכים</h6>
                  </Link>
                </div>
                <div className="circle_2s">
                  <Link to="/feel-need-text" className="line">
                    <h6>רשימת רגשות וצרכים</h6>
                  </Link>
                </div>
                <div className="circle_3s">
                  {/* self-connect */}
                  <Link to="/self-connect" className="line">
                    <h6>חיבור לעצמי</h6>
                  </Link>
                </div>
                <div className="circle_4s" onClick={handleClick}>
                  <h6>ניסוח בקשה</h6>
                </div>
              </div>
            </div>
          </div>
          <div className="svg-img svg-img_home">
            <MinorShape />
          </div>
          <div className="footer_a7_content footer_a7_content_s bg-blue-home">
            <div className="container px-5">
              <a
                href="#"
                className="line"
                data-bs-toggle="modal"
                data-bs-target="#youtube_modal"
              >
                <button className="btn bg-white-outline_s">סרטון הסבר</button>
              </a>
            </div>
            <img
              src="assets/images/white-logo.svg"
              style={{ height: 41 }}
              alt="Logo"
            />
            <p className="m-0">
              © כל הזכוייות שמורות ל-{" "}
              <a href="https://www.mpathicfamily.com/">Mpathic family</a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

const Home = () => {
  const screenHeight = window.innerHeight;
  return <>{screenHeight < 640 ? <HomeS /> : <HomeL />}</>;
};
export default Home;
